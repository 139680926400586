import httpManager from "../axios/httpManager";

export function getIpList (search, pageNum, pageSize) {
    let url = '/ip/list'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}
export function saveIp (ip) {
    return httpManager.post('/ip/save', ip);
}
export function updateIp (ip) {
    return httpManager.post('/ip/update', ip);
}

export function delIp (id) {
    return httpManager.get('/ip/del?id='+ id + '&t=' + new Date().getTime());
}