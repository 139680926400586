<template>
    <div class="main">
        <div class="search">
            <span style="font-weight: bold;font-size: 14px;color: #fff9ff">관리자 비밀번호 변경</span>
        </div>
        <div class="data">
            <table class="table100">
                <tr>
                    <th style="width: 20%">아이디</th>
                    <th style="width: 20%">현재비밀번호</th>
                    <th style="width: 20%">새비밀번호</th>
                    <th style="width: 20%">새비밀번호 확인</th>
                    <th style="width: 20%">PIN</th>
                </tr>
                <tr>
                    <td>
                        <el-input size="mini" v-model="manager.account" style="width: 80%"></el-input>
                    </td>
                    <td>
                        <el-input size="mini" v-model="manager.oldPasswd" style="width: 80%"></el-input>
                    </td>
                    <td>
                        <el-input size="mini" v-model="manager.passwd" style="width: 80%"></el-input>
                    </td>
                    <td>
                        <el-input size="mini" v-model="manager.chkPasswd" style="width: 80%"></el-input>
                    </td>
                    <td>
                        <el-input size="mini" v-model="manager.mpin" style="width: 80%"></el-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="padding: 10px 0">
                        <el-button size="mini" type="primary" @click="save">비밀번호 업데이트</el-button>
                    </td>
                </tr>

            </table>
        </div>
    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {
        deleteCusTemp,
        getCusTempList,
        saveCusTemp,
        updateCusTemp
    } from "../../network/manager/customerTemplateRequest";
    import {delIp, getIpList, saveIp} from "../../network/manager/ipcontainerRequest";
    import {updateManagerPassword} from "../../network/manager/commonRequest";

    export default {
        name: "ManagerChangePasswd",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                manager: {},
            }
        },
        methods: {
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateManagerPassword(this.manager).then(res => {
                    loadingInstance.close()
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '비밀번호가 변경되였습니다'
                        });
                        this.manager={}
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 2000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },

        },
        created() {
        },
        watch: {}
    }
</script>

<style scoped>

</style>